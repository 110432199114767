import { BlogCategory } from '@stars-ecom/shared-organisms-ui'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import { Helmet } from 'react-helmet'

import { lengthLimiter } from '../utils/metaTitleFormatter'
import { simplifyGQLResult } from '../utils/strapi'

const BlogCategoryPage = (props) => {
  const { data } = props

  const blogCategory = simplifyGQLResult(get(data, 'strapi.blogCategory'))

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: 'chakra-ui-light'
        }}>
        <meta charSet="utf-8" />
        <title>{lengthLimiter(blogCategory?.metaTitle)}</title>
        <meta name="description" content={blogCategory?.metaDescription} />
      </Helmet>
      <BlogCategory blogCategory={blogCategory} />
    </>
  )
}

export const query = graphql`
  query blogCategory(
    $idBlogCategory: ID
    $startDate: Strapi_DateTime
    $endDate: Strapi_DateTime
    $websiteId: ID
  ) {
    strapi {
      blogCategory(id: $idBlogCategory) {
        data {
          id
          attributes {
            title
            metaTitle
            metaDescription
            backgroundColor
            description
            blog_articles(
              filters: {
                websites: { id: { eq: $websiteId } }
                or: [
                  { endDate: null, startDate: null }
                  { endDate: { gte: $endDate }, startDate: null }
                  { endDate: null, startDate: { lte: $startDate } }
                  { endDate: { gte: $endDate }, startDate: { lte: $startDate } }
                ]
              }
              pagination: { limit: 500 }
              sort: ["startDate:DESC", "publishedAt:DESC"]
            ) {
              data {
                id
                attributes {
                  title
                  subTitle
                  hook
                  content
                  path
                  thumbnail_image {
                    data {
                      attributes {
                        url
                        mime
                        image {
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, width: 830, height: 496)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            backgroundImage {
              data {
                attributes {
                  url
                  mime
                  image {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        width: 1585
                        height: 787
                        formats: [JPG]
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

BlogCategoryPage.pageType = 'blog'

export default BlogCategoryPage
